<template>
    <doctors-module-component/>
</template>

<script>
    import DoctorsModuleComponent from "@/components/admin/modules/DoctorsModuleComponent";
    
    export default {
        name: "DoctorsModule",
        title: "Gestión de Médicos | Turismo BC",
        components: {DoctorsModuleComponent}
    }
</script>

<style scoped>

</style>